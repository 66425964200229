




















































































































































































































































































































































































































































.v-data-table {
  strong {
    white-space: nowrap;
  }
}
